// ! Change Cart by your page name in CamelCase
// ! Change cart by your component name in lower case
// ! Change cart by your trn namespace in lower case
// ! Change templateAction by your action name in lower case
/**
 * Component for rendering a template page.
 * @module Cart
 * @param {Object} _props - The component properties.
 * @param {Object} _props.properties - The properties for the component.
 * @param {string} _props.pageName - The name of the page.
 * @param {string} _props.trNamespace - The translation namespace for the page.
 * @param {string} _props.icon - The icon for the page.
 * @returns {JSX.Element} The rendered template page.
 */
import React, { useState, useEffect } from "react";
import { renderToString } from "react-dom/server";
import CustomSpinner from "../common/shared/custom-spinner.js";
import PropTypes from "prop-types";
import { useToastContext } from "../../context/toast-context.js";
import { initTranslations } from "../../i18n/i18n.js";
import HeadMenu from "../common/headers/head-menu.js";
import LegalsComponent from "../common/specific/legals-component.js";
import {
	LoadDetailsData,
	logingWithLocalDb,
	logout
} from "../../data/services/load-data-service.js";
import orderService from "../../data/services/application/order.js";
const RitsReactConsole = require("rits-node-framework/debug/rits-react-console.js");
const _logger = new RitsReactConsole(global.LOG_LEVEL);
import { setTheme, getTheme } from "../models/themes.js";
const _basketModel = require("../models/basket.js");
const _companyModel = require("../models/company.js");
const _paymentModel = require("../models/payment.js");
const _guestModel = require("../models/guest.js");
const _openingHoursModel = require("../models/opening-hours.js");
const _deliveryModel = require("../models/delivery.js");

import LoginForm from "../common/forms/login-form.js";
import OrderThumbnail from "../common/specific/order-thumbnail.js";
import CreateAccount from "../common/forms/create-account.js";
import GuestForm from "../common/forms/guest-form.js";
import PaymentForm from "../common/forms/payment-form.js";
import CustomFooter from "../common/shared/custom-footer.js";

/**
 * The Cart component.
 * @param {Object} _props - The component properties.
 * @param {Object} _props.properties - The properties for the component.
 * @param {string} _props.pageName - The name of the page.
 * @param {string} _props.trNamespace - The translation namespace for the page.
 * @param {string} _props.icon - The icon for the page.
 * @returns {JSX.Element} The rendered template page.
 */

const DynamicComponent = (props) => {
	return (
		<PaymentForm
			properties={{
				pageName: props.pageName,
				trNamespace: props.trNamespace,
				image: props.base64Image
			}}
		></PaymentForm>
	);
};

const Cart = (_props) => {
	var props = _props.properties;
	const componentId = "page-Cart-" + Math.random().toString(36).substring(7);
	const [companyDatas, setCompanyDatas] = useState(null);

	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	const [userEmail, setUserEmail] = useState(null);
	const [loaded, setLoaded] = useState(false);
	var step = 0;
	var maxStep = 2;
	var _cpLocal = _companyModel.getCompanyDatas();
	var _deliveries = _deliveryModel.getDelivery(_cpLocal.companyId);
	const [showComponent, setShowComponent] = useState(false);
	// Specific
	setTheme(getTheme());

	useEffect(() => {
		if (loaded) return;
		// Reload data on loaded state change.
		componentDidMount();
	}, [loaded]);

	//#region Lifecycle methods
	/**
	 * Lifecycle method called when the component is mounted.
	 */
	async function componentDidMount() {
		try {
			if (companyDatas != null) return;
			_logger.DEBUG(`Loaded ${props.pageName} with id ${componentId}`);
			await loadAll();
		} catch (e) {
			if (process.env.REACT_APP_APIS_URL.includes("localhost")) { onError(e); }
		}
	}
	/**
	 * Load all data.
	 */
	async function loadAll() {
		try {
			var data = await LoadDetailsData(true);
			if (data != null && data != undefined && data.companyName != null) {
				var basket = _basketModel.getCpyBasket(_cpLocal.companyId);
				setCompanyDatas(data);
				var p = await _basketModel.checkProductsDeactivated(basket);
				if (p != null && p.length > 0) {
					var toastContent = "<ul>";
					for (var i = 0; i < p.length; i++) {
						var product = p[i].Product;
						var variant = "";
						var qty = p[i].TotalQty;
						if (p[i].Variant.variantName != t("menu:price") || p[i].Variant.variantName != t("menu:classic")) {
							variant = p[i].Variant.variantName;
						}
						toastContent += `<li>${qty}x ${product} ${variant} ${t("orders:removed-products")}</li>`;
					}
					toastContent += "</u>";
					_basketModel.setBasketPriceAndQty({
						currency: data.company.CompanyParams.Currency,
						cpid: _cpLocal.companyId
					});
					sendToast(toastContent, "warning", t("common:out-of-stock"));

				}
				if (
					!data.company.CompanyParams.CanOrder ||
					basket.products.length == 0
				) {
					// Navigate to menu
					window.location.href = `/${_cpLocal.companyId}/menu`;
				}
				props.pageName = data.companyName;
				props.base64Image = data.companyDetails.CompanyLogo;
				setTheme(data.company.CompanyParams);
				var logged = await logingWithLocalDb();
				if (logged) {
					if (global.LOGGED_USER != null)
						setUserEmail(global.LOGGED_USER.Email);
					_logger.WARN(
						`Loaded ${props.pageName} with id ${componentId} and company ${data.companyName} logged user ${userEmail}`
					);
				}
				document.title = props.pageName;
				setLoaded(true);
				// temporing
				setTimeout(() => {
					setLoadFunction(data);
				}, 500);
			} else {
				setTimeout(async () => {
					await loadAll();
				}, 100);
			}
		} catch (e) {
			// load menu
			if (process.env.REACT_APP_APIS_URL.includes("localhost")) { onError(e); }
			//window.location.href = `/${_cpLocal.companyId}/menu`;
		}
	}


	/**
	 * Set the load function.
	 * @param {*} data
	 * @returns
	 */
	function setLoadFunction(data) {
		try {
			if (_deliveries == null) {
				saveDateTime();
			}
			_basketModel.setBasketPriceAndQty({
				currency: data.companyDetails.Currency,
				cpid: data.company.CompanyId
			});
			if (global.LOGGED_USER != null) {
				stepForward();

				if (checkPayment()) {
					setShowComponent(true);
				}
				step = 1;
			}

			setDisablePay();
			setDeleteButtons();
		} catch {
			setTimeout(() => {
				setLoadFunction(data);
			}, 50);
		}
	}

	/**
	 * Handles errors and shows a toast message.
	 * @param {Error} error - The error object.
	 */
	function onError(error) {
		sendToast(
			`From Cart ${error}`,
			"error",
			t("error", { ns: props.trNamespace })
		);
	}
	/**
	 * Sends a toast message.
	 * @param {*} message
	 * @param {*} severity
	 * @param {*} title
	 */
	function sendToast(message, severity, title) {
		handleShowToast(severity, title, `${message}`);
	}
	//#endregion

	//#region Logic
	//! Login
	async function submitLogin() {
		if (checkGuest()) {
			localStorage.removeItem("guest");
		}

		// reload url
		window.location.reload();
	}
	//! Guest
	function checkGuest() {
		return _guestModel.checkGuest(_cpLocal.companyId) != null;
	}
	async function logGuest() {
		var guest = JSON.stringify({
			firstName: document.getElementById("guest_firstName").value,
			lastName: document.getElementById("guest_lastName").value,
			email: document.getElementById("guest_email").value,
			phoneNumber: document.getElementById("guest_phoneNumber").value
		});
		_guestModel.setGuest(guest, _cpLocal.companyId);
		stepForward();
	}
	//! Payment
	function checkPayment() {
		var pay = _paymentModel.checkPayment(_cpLocal.companyId);
		var isCheck = false;
		try {
			if (pay != null) {
				isCheck = pay.context != null;
			}
		} catch {
			isCheck = false;
		}
		return isCheck;
	}
	function validateCart() {
		// check if logged
		if (global.LOGGED_USER == null && !checkGuest()) {
			sendToast("Please login", "error", "Login");
			return;
		} else {
			saveDateTime();

			resetForm();
			setTimeout(() => {
				var payform = document.getElementById("pay-form-body");
				if (!payform) {
					_paymentModel.createPaymentStep(_cpLocal.companyId);
					setShowComponent(true);
				}
				stepForward(2);
			}, 100);
		}
	}
	function resetForm() {
		// get id dyna-content
		var dynaContent = document.getElementById("dyna-content");
		// if not null
		if (dynaContent) {
			dynaContent.innerHTML = "";
		}
		var payform = document.getElementById("pay-form-body");
		// remove payform
		if (payform) {
			payform.remove();
		}
		// remove all children

		setShowComponent(false);
	}

	//#endregion

	//#region Steps
	//! Progress bar design
	function setProgressButtonClear() {
		// get all data-type="step-btn"
		var stepButtons = document.querySelectorAll("[data-type='step-btn']");
		// set all buttons to badge bg-light border-secondary
		stepButtons.forEach((element) => {
			element.className = "badge bg-light border-secondary";
		});
	}
	function setProgressButtonActive(id) {
		var stepElement = document.getElementById(id);
		if (stepElement) {
			stepElement.className = "badge bg-primary-gradient border-primary";
		}
	}
	function setProgressBar() {
		var progressStep = 100 / maxStep;
		setProgress(progressStep * step);
		// scroll to top
		window.scrollTo(0, 0);
	}
	//! Steps design
	function hideAllSteps() {
		for (var i = 0; i <= maxStep; i++) {
			var stepcontent = document.getElementById("step-content-" + i);
			if (stepcontent) stepcontent.style.display = "none";
		}
	}
	function showOneStep(step) {
		hideAllSteps();
		var stepcontent = document.getElementById("step-content-" + step);
		if (stepcontent) stepcontent.style.display = "block";
		loadDateTimeValues();
	}
	//! Steps logic
	function setStep(__step) {
		var stepcontent = document.getElementById("step-content-2");
		if (stepcontent.style.display == "block") {
			step = 2;
		} else {
			resetForm();
		}
		if (global.LOGGED_USER != null) {
			step = 1;
		}

		if (__step > step) return;
		if (global.LOGGED_USER == null && !checkGuest()) {
			sendToast("Please login", "error", "Login");
			return;
		}
		step = __step;

		setProgressButtonClear();
		setProgressButtonActive("step-" + step);
		showOneStep(step);
		setProgressBar();
	}
	function stepForward(__step) {
		if (__step != null) {
			step = __step;
		}
		step++;
		if (step > maxStep) {
			step = maxStep;
		}

		setProgressButtonClear();
		setProgressButtonActive("step-" + step);
		setProgressBar();

		setTimeout(() => {
			showOneStep(step);
			loadDateTimeValues();
		}, 100);
	}
	function setProgress(progressValue) {
		var progressElement = document.getElementById("progress-cart");
		progressElement.style.width = progressValue + "%";
		progressElement.setAttribute("aria-valuenow", progressValue);
	}

	function setDeleteButtons() {
		var basket = _basketModel.getCpyBasket(_cpLocal.companyId);
		var productsBasket = basket.products;
		var deleteButtons = document.getElementsByClassName("btn-order-delete");
		for (var i = 0; i < deleteButtons.length; i++) {
			deleteButtons[i].addEventListener("click", function () {
				// if confirm
				if (confirm(t("orders.products.delete", { ns: "orders" }))) {
					var itemId = this.getAttribute("data-item-id");
					var item = productsBasket.find((x) => x.BasketId == itemId);
					_basketModel.deleteBasketProduct(
						item.BasketId,
						_cpLocal.companyId,
						_cpLocal.currency
					);
					// get element {`order-item-${props.item.BasketId}`}
					var orderItem = document.getElementById(
						`order-item-${item.BasketId}`
					);
					// remove element
					orderItem.remove();
					// get basket
					var __basket = _basketModel.getCpyBasket(_cpLocal.companyId);
					var __products = __basket.products;
					if (__products.length == 0) {
						window.location.href = `/${_cpLocal.companyId}/menu`;
					}
				}
			});
		}
	}
	//#endregion

	//#region Components
	//! OrderThumbnail component
	function setProductOrderThumbnail() {
		var productsBasket = [];
		var basket = _basketModel.getCpyBasket(_cpLocal.companyId);
		productsBasket = basket.products;
		return productsBasket.map((item, count) => {
			count++;
			return (
				<div className="col-xs-12 col-md-6 col-lg-4 p-2 " key={count}>
					<OrderThumbnail
						properties={{ item: item, trNamespace: "orders", count: count, changeQtyEvent: changeQtyEvent }}
					></OrderThumbnail>
				</div>
			);
		});
	}
	async function changeQtyEvent() {
		var openingHours = companyDatas.company.CompanyParams.OpeningHours;
		var ohmodel = _openingHoursModel.getOpeningHours({
			openingHours: openingHours,
			t: t
		});
		// load onChange event of select
		var select = document.getElementById("delivery-date-select");
		// selecte first option
		var _options = select.options;
		select.value = _options[0].value;
		var basket = _basketModel.getCpyBasket(_cpLocal.companyId);
		var slots = await orderService.serverHours({ openingHours: ohmodel, companyDatas: companyDatas, day: new Date(_options[0].value), basket: basket });
		setServerTimeOptions(slots);
		setTimeout(() => {
			setDisablePay();
		}, 500);
	}

	//! Login component
	function setLoginItem() {
		var loginform = null;
		if (global.LOGGED_USER == null) {
			loginform = (
				<LoginForm
					properties={{
						pageName: "login",
						trNamespace: "login",
						submitFunction: submitLogin
					}}
				></LoginForm>
			);
		} else {
			// Btn-danger logout
			loginform = (
				<button
					className="btn btn-danger mb-4"
					onClick={async (e) => {
						// Disable this button
						e.target.disabled = true;
						var loggedOut = await logout();
						if (loggedOut) {
							window.location.href = `/${_cpLocal.companyId}/cart`;
						}
					}}
				>
					Logout
				</button>
			);
		}
		return (
			<div className="accordion-item m-2">
				<h2 className="accordion-header">
					<button
						className="accordion-button bg-light txt-info"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#collapseOne"
						aria-expanded="true"
						aria-controls="collapseOne"
					>
						<span className="w-100 text-center font-bolder">
							<div className="container-inline-centered">
								<h2 className="text-info">{t("login:already-registered")}</h2>
								<img
									src={require("../../rits-package/img/logos/AO2-reduit.png")}
									alt="AO2"
									className="img-sm"
								/>
							</div>
						</span>
					</button>
				</h2>
				<div
					id="collapseOne"
					className="accordion-collapse collapse show"
					data-bs-parent="#accordion1"
				>
					<div className="accordion-body"></div>
					<center>
						<div className="col-xs-12 col-md-6 col-lg-4 p-2 animate blur">
							{loginform}
						</div>
					</center>
				</div>
			</div>
		);
	}
	//! CreateAccount component
	function setCreateAccountItem() {
		return (
			<div className="accordion-item m-2">
				<h2 className="accordion-header">
					<button
						className="accordion-button  bg-light"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#collapse2"
						aria-expanded="false"
						aria-controls="collapse2"
					>
						<span className="w-100 text-center">
							<div className="container-inline-centered">
								<h2 className="text-info">{t("login:need-register")}</h2>
								<img
									src={require("../../rits-package/img/logos/AO2-reduit.png")}
									alt="AO2"
									className="img-sm"
								/>
							</div>
						</span>
					</button>
				</h2>
				<div
					id="collapse2"
					className="accordion-collapse collapse"
					data-bs-parent="#accordion1"
				>
					<div className="accordion-body"></div>
					<center>
						<div className="col-xs-12 col-md-12 col-lg-12 p-2 animate blur">
							<CreateAccount
								properties={{
									pageName: "create-account",
									trNamespace: "login",
									submitFunction: submitLogin
								}}
							></CreateAccount>
						</div>
					</center>
				</div>
			</div>
		);
	}
	//! GuestForm component
	function setGuestCardItem() {
		return (
			<div className="accordion-item m-2">
				<h2 className="accordion-header">
					<button
						className="accordion-button bg-light"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#collapse3"
						aria-expanded="false"
						aria-controls="collapse3"
					>
						<span className="w-100 text-center">{t("login:need-gest")}</span>
					</button>
				</h2>
				<div
					id="collapse3"
					className="accordion-collapse collapse"
					data-bs-parent="#accordion1"
				>
					<div className="accordion-body"></div>
					<center>
						<div className="col-xs-12 col-md-6 p-2 animate blur">
							<GuestForm
								properties={{
									pageName: "order-info",
									trNamespace: "login",
									submitFunction: logGuest
								}}
							></GuestForm>
						</div>
					</center>
				</div>
			</div>
		);
	}
	//! Progress form component
	function setProgressForm() {
		// Circles with 3 steps and progress bar behind
		return (
			<div style={{ height: "100px" }}>
				<div className="" style={{ height: "50px" }}>
					<div className="parent-superpose">
						<div className="child-superpose1 w-100" style={{ top: "8px" }}>
							<div className="progress" style={{ height: "10px" }}>
								<div
									className="progress-bar bg-success"
									id="progress-cart"
									role="progressbar"
									style={{ width: "0%", height: "10px" }}
									aria-valuenow="0"
									aria-valuemin="0"
									aria-valuemax="100"
								></div>
							</div>
						</div>
						<div className="child-superpose2 w-100">
							<div className="row p-0 m-0 w-100">
								<div className="col-4 p-0 m-0  d-flex justify-content-start align-content-center">
									<h4>
										<button
											className="badge bg-primary-gradient border-primary"
											id="step-0"
											data-type="step-btn"
											onClick={() => {
												setStep(0);
											}}
										>
											1 - {t("orders:login")}
										</button>
									</h4>
								</div>
								<div className="col-4 p-0 m-0 d-flex justify-content-center align-content-center">
									<h4>
										<button
											className="badge bg-light border-secondary"
											id="step-1"
											data-type="step-btn"
											onClick={() => {
												setStep(1);
											}}
										>
											2 - {t("orders:cart")}
										</button>
									</h4>
								</div>
								<div className="col-4 p-0 m-0 d-flex justify-content-end">
									<h4>
										<button
											className="badge bg-light border-secondary"
											id="step-2"
											data-type="step-btn"
										>
											3 - {t("orders:pay")}
										</button>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="container-inline-centered">
						<h2>
							<span className="  badge bg-primary txt-secondary">
								<span>{t("orders:products")}:</span>
								<span
									className=" m-2 mb-0 mt-0"
									data-id="basket-count-articles"
								></span>
								<span className="m-2 mb-0 mt-0">
									{t("orders:total-price")}:
								</span>
								<span className=" m-2 mb-0 mt-0" data-id="basket-count"></span>
							</span>
						</h2>
						<h2 className=""></h2>
					</div>
				</div>
			</div>
		);
	}
	//! Step 1
	function setLoginForm() {
		var containerStyle = { display: "block" };
		if (global.LOGGED_USER != null && !checkGuest()) {
			containerStyle = { display: "none" };
		}

		return (
			<div className="m-2" id="step-content-0" style={containerStyle}>
				<div className="accordion" id="accordion1">
					{setLoginItem()}
					{setCreateAccountItem()}
					{setGuestCardItem()}
				</div>
			</div>
		);
	}
	//! Step 1
	function setBasket() {
		var containerStyle = { display: "none" };
		if (global.LOGGED_USER != null && !checkGuest()) {
			containerStyle = { display: "block" };
		}

		var selectTime = (
			<div className="card ">
				<div className="card-header bg-info">
					<h3 className="txt-info">{t("orders:delivery-info")}</h3>
				</div>
				<div className="card-body bg-light  border-rounded">
					<div className="row">
						<div className="col-8">
							<label
								htmlFor="time"
								className="badge txt-dark font-bold float-start"
							>
								{t("orders:select-day")}
							</label>
							<span id="delivery-day">{setDateOptions()}</span>
						</div>
						<div className="col-4">
							<label
								htmlFor="time"
								className="badge txt-dark font-bold float-start"
							>
								{t("orders:select-time")}
							</label>
							<span id="delivery-time">{setTimeOptions()}</span>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<label
								htmlFor="order-comment"
								className="badge txt-dark font-bold float-start"
							>
								{t("orders:comment.label")}
							</label>
							<textarea
								className="form-control"
								id="order-comment"
								rows="4"
								placeholder={t("orders:comment.placeholder")}
								onChange={() => {
									saveDateTime();
								}}
							></textarea>
						</div>
					</div>
				</div>
			</div>
		);

		return (
			<div className="m-2" id="step-content-1" style={containerStyle}>
				{selectTime}
				<div className="card mt-4">
					<div className="card-header bg-info">
						<h3 className="txt-info">{t("orders:order-summary")}</h3>
					</div>
					<div className="card-body bg-light  border-rounded">
						<div className="row d-flex justify-content-center">
							{setProductOrderThumbnail()}
						</div>
					</div>
				</div>
				<div className="app-separator-large"></div>
				<LegalsComponent
					properties={{
						pageName: props.pageName,
						trNamespace: props.trNamespace,
						show: companyDatas.company.EnableAlcohol
					}}
				></LegalsComponent>
				<div className="footer footer-bottom footer-bottom-xl bg-secondary-gradient d-flex align-items-center justify-content-center">
					<button
						className="btn btn-success btn-lg"
						id="validate-cart"
						onClick={() => {
							saveDateTime();
							validateCart();
						}}
					>
						<i className="fa fas fa-cash-register"></i>&nbsp;
						{t("orders:validate-and-pay")}
					</button>
				</div>

			</div>
		);
	}
	//! Step 3
	function setPaymentForm() {
		var containerStyle = { display: "none" };
		if (global.LOGGED_USER != null && !checkGuest()) {
			containerStyle = { display: "block" };
		}
		return (
			<div className="m-2" id="step-content-2" style={containerStyle}>
				<div
					className="animate blur container-inline-centered"
					id="dyna-content"
				>
					{showComponent && (
						<DynamicComponent
							properties={{
								pageName: props.pageName,
								trNamespace: props.trNamespace,
								image: props.base64Image
							}}
						/>
					)}
				</div>
				<LegalsComponent
					properties={{
						pageName: props.pageName,
						trNamespace: props.trNamespace,
						show: companyDatas.company.EnableAlcohol
					}}
				></LegalsComponent>


			</div>
		);
	}
	//#endregion

	//! Set dates and times
	var precommandes = 2;

	//! Set dates and times
	function setTimeOptions(date = null) {
		var openingHours = companyDatas.company.CompanyParams.OpeningHours;

		if (date != null) {
			//if date is today, get now()
			var today = new Date();
			var selectedDate = new Date(date);
			if (today.getDate() == selectedDate.getDate()) {
				date = null;
			}
		}

		var ohmodel = _openingHoursModel.getOpeningHours({
			openingHours: openingHours,
			t: t,
			chosenDate: date
		});
		if (ohmodel.open == false) {
			date = new Date(ohmodel.nextDate);
			// set time to 4:00
			date.setHours(4);
			date.setMinutes(0);
			ohmodel = _openingHoursModel.getOpeningHours({
				openingHours: openingHours,
				t: t,
				chosenDate: date
			});
		}
		var deliveryHours = _openingHoursModel.setDeliveryHours({
			openingHours: ohmodel,
			companyDatas: companyDatas.company,
			day: date
		});
		// map deliveryHours to options with text = value and value is date now with indicated time
		var options = deliveryHours.map((item, id) => {
			id++;
			var dt = new Date();
			if (date == null) {
				dt.setHours(item.split(":")[0]);
				dt.setMinutes(item.split(":")[1]);
			} else {
				dt = new Date(date);
				dt.setHours(4);
				dt.setMinutes(0);
			}
			return (
				<option key={id} value={item}>
					{item}
				</option>
			);
		});
		setTimeout(() => {
			setDisablePay();
			loadDateTimeValues();
		}, 500);
		// Add options to timeSelect
		return (
			<select
				className="form-select"
				id="delivery-time-select"
				onChange={() => {
					saveDateTime();
				}}
			>
				{options}
			</select>
		);
	}
	function setServerTimeOptions(hours) {
		// map deliveryHours to options with text = value and value is date now with indicated time		
		_deliveries = _deliveryModel.getDelivery(_cpLocal.companyId);
		if (hours == null || hours.length == 0) return null;
		var options = hours.map((item, id) => {
			id++;
			return (
				<option key={id} value={item}>
					{item}
				</option>
			);
		});
		setTimeout(() => {
			setDisablePay();
		}, 500);
		// Get Select "delivery-time-select"
		var select = document.getElementById("delivery-time-select");
		// clear options and set news
		select.innerHTML = renderToString(options);
		return options;
	}


	function setDateOptions() {
		var openingHours = companyDatas.company.CompanyParams.OpeningHours;
		var ohmodel = _openingHoursModel.getOpeningHours({
			openingHours: openingHours,
			t: t
		});
		// Set days
		var firstDay = ohmodel.nextDate ?? new Date();
		var dayRes = [];
		if (!ohmodel.open) {
			precommandes = 1;
		}
		for (var i = 0; i < precommandes; i++) {
			// set date is firstDay + 1 day
			var dt = new Date(firstDay);
			dt = dt.setDate(dt.getDate() + i);
			var item = setOrderDays(dt + i, openingHours);
			if (item.open) {
				var day = dt;
				dayRes.push(day);
			} else {
				day = new Date(item.nextDate);
				dayRes.push(day);
			}
		}
		//
		_deliveries = _deliveryModel.getDelivery(_cpLocal.companyId);
		// if selectedDate is null, set to first option
		if (_deliveries == null || _deliveries.selectedDate == null) {
			_deliveries = {
				selectedDate: dayRes[0],
				selectedTime: null,
				comment: ""
			};

		}
		// Distinct dayRes
		dayRes = [...new Set(dayRes)];

		// map deliveryHours to options with text = value and value is date now with indicated time
		var options = dayRes.map((item, id) => {
			id++;
			// get date format (yyyy-mm-dd)
			var date = new Date(item).toISOString().split("T")[0];
			var formattedDate = date;
			if (process.env.REACT_APP_IS_FRENCH == "true") {
				formattedDate = new Date(item).toLocaleDateString("fr-FR");
			}

			return (
				<option key={id} value={date}>
					{formattedDate}
				</option>
			);
		});
		// Add options to timeSelect
		setTimeout(async () => {
			// load onChange event of select
			var select = document.getElementById("delivery-date-select");
			// selecte first option
			var _options = select.options;
			select.value = _options[0].value;
			var basket = _basketModel.getCpyBasket(_cpLocal.companyId);
			var slots = await orderService.serverHours({ openingHours: ohmodel, companyDatas: companyDatas, day: new Date(_options[0].value), basket: basket });
			var timeslots = setServerTimeOptions(slots);
			console.log("timeslots:", timeslots);
			if (timeslots == null) {
				// remove this option
				select.remove(0);
				//select.value = _options[1].value;
				basket = _basketModel.getCpyBasket(_cpLocal.companyId);
				slots = await orderService.serverHours({ openingHours: ohmodel, companyDatas: companyDatas, day: new Date(_options[0].value), basket: basket });
				timeslots = setServerTimeOptions(slots);
			}
		}, 1000);
		return (
			<select
				className="form-select"
				id="delivery-date-select"
				onChange={async (e) => {
					// get selected options
					var options = e.target.options;
					var selectedOption = options[options.selectedIndex].value;
					var basket = _basketModel.getCpyBasket(_cpLocal.companyId);

					//var selectTime = setTimeOptions(selectedOption);
					var slots = await orderService.serverHours({ openingHours: ohmodel, companyDatas: companyDatas, day: new Date(selectedOption), basket: basket });
					setServerTimeOptions(slots);
					//get delivery-time
					//var deliveryTime = document.getElementById("delivery-time");
					//deliveryTime.innerHTML = renderToString(selectTime);
					saveDateTime();
				}}
			>
				{options}
			</select>
		);
	}
	function setOrderDays(firstDay, openingHours) {
		// check if nextDay is open
		var ohmodel = _openingHoursModel.getOpeningHours({
			openingHours: openingHours,
			t: t,
			chosenDate: firstDay
		});

		return ohmodel;
	}
	function saveDateTime() {
		var selectedDate = document.getElementById("delivery-date-select").value;
		var selectedTime = document.getElementById("delivery-time-select").value;
		// get comment
		var comment = document.getElementById("order-comment").value;
		_deliveryModel.setDelivery(
			{
				selectedDate: selectedDate,
				selectedTime: selectedTime,
				comment: comment ?? ""
			},
			_cpLocal.companyId
		);
		_deliveries = _deliveryModel.getDelivery(_cpLocal.companyId);
		if (selectedDate != null && selectedTime != null) {
			var btn = document.getElementById("validate-cart");
			if (btn) {
				btn.disabled = false;
			}
		}
	}

	function loadDateTimeValues() {
		if (_deliveries == null) {
			_deliveries = _deliveryModel.getDelivery(_cpLocal.companyId);
		}
		// Check if document.getElementById("delivery-date-select") has option with value = _deliveries.selectedDate
		var sel1 = document.getElementById("delivery-date-select");
		var selectedDate = null;
		var selectedTime = null;
		var found = false;
		for (var i = 0; i < sel1.options.length; i++) {
			if (sel1.options[i].value == _deliveries?.selectedDate) {
				found = true;
				break;
			}
		}
		if (found) {
			document.getElementById("delivery-date-select").value =
				_deliveries.selectedDate;
		} else {
			// Set _deliveries.selectedDate to first option
			selectedDate = sel1.options[0].value;
		}
		var sel2 = document.getElementById("delivery-time-select");
		var found2 = false;
		for (i = 0; i < sel2.options.length; i++) {
			if (sel2.options[i].value == _deliveries?.selectedTime) {
				found2 = true;
				break;
			}
		}
		if (found2) {
			document.getElementById("delivery-time-select").value =
				_deliveries.selectedTime;
		} else {
			// Set _deliveries.selectedTime to first option
			selectedTime = sel2.options[0].value;
		}
		if (!found || !found2) {
			// set delivery
			_deliveryModel.setDelivery(
				{
					selectedDate: selectedDate,
					selectedTime: selectedTime,
					comment: _deliveries.comment ?? document.getElementById("order-comment").value ?? ""
				},
				_cpLocal.companyId
			);
		}
		document.getElementById("order-comment").value = _deliveries.comment;
	}
	function setDisablePay() {
		//check if select time has options
		var selectTime = document.getElementById("delivery-time-select");
		// if no options disable btns
		if (selectTime != null && selectTime.options.length == 0) {
			var btn = document.getElementById("step-2");
			if (btn) {
				btn.disabled = true;
			}
			btn = document.getElementById("validate-cart");
			if (btn) {
				btn.disabled = true;
			}
		}
	}
	//! Render
	return !loaded ? (
		<div className="App bg-primary h-100">
			<header className="App-header">
				<CustomSpinner
					properties={{
						pageName: props.pageName
					}}
				/>
			</header>
		</div>
	) : (
		<div className="App">
			<input type="hidden" id={componentId} />
			<HeadMenu
				properties={{
					pageName: "header",
					trNamespace: "headMenu",
					image: props.base64Image,
					title: props.pageName,
					addedClass: "w-100 bg-primary-gradient ",
					textClass: "txt-primary",
					menuButtonClass: "txt-primary m-2",
					showOrders: false,
					showMenu: true,
					showLogin: false
				}}
			/>
			<div className="hilightbg-sticky-top-header bottom-rounded bg-secondary-gradient shadow w-100 h-100">
				<div className=" m-2">{setProgressForm()}</div>
			</div>
			<div className="content-container  container-fluid">
				<div className="content-margin-top " key={componentId}>
					{setLoginForm()}
					{setBasket()}
					{setPaymentForm()}
				</div>
			</div>
			<div className="app-separator-large" style={{ marginBottom: "100px" }}></div>
			<CustomFooter></CustomFooter>
		</div>
	);
};

Cart.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	icon: PropTypes.string,
	base64Image: PropTypes.string
};

DynamicComponent.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	icon: PropTypes.string,
	base64Image: PropTypes.string
};

export default Cart;
